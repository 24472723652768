<template>
  <fragment>
    <td :style="indCupo < totalCupos - 1 ? 'border: 0px solid black;' : ''"></td>
    <td
      :style="indCupo < totalCupos - 1 ? 'border: 0px solid black;' : ''"
      :colspan="tipoContrato === 'categoria' ? 2 : 2"
    >
      <v-autocomplete
        v-model="cupo.oficina_id"
        class="pt-2"
        :items="oficinas"
        :search-input.sync="searchOficina"
        hide-details
        :label="$t('menu.oficinaRenta')"
        outlined
        dense
        item-text="name"
        item-value="id"
        multiple
        clearable
        :disabled="$store.state.app.onlyShow"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('menu.oficinaRenta') }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </td>
    <td :style="indCupo < totalCupos - 1 ? 'border: 0px solid black;' : ''">
      <v-text-field
        v-model="cupo.cuposDisponible"
        style="width:85px;"
        outlined
        dense
        hide-details
        :label="cupo.cuposDisponible ? $t('lbl.cupo') : ''"
        placeholder="OR"
        type="number"
        min="0"
        :disabled="$store.state.app.onlyShow"
      ></v-text-field>
    </td>
    <td :style="indCupo < totalCupos - 1 ? 'border: 0px solid black;' : ''">
      <v-tooltip
        v-if="totalCupos > 1 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteCupoPricesTemporadasContrateCar({ posPrice: indPrice, posCupo: indCupo })"
          >
            <v-icon small>
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="indCupo === 0 && !$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addCupoPricesTemporadasContrateCar(indPrice)"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </td>
  </fragment>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiPlus, mdiDeleteOutline } from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    cupo: {
      type: Object,
    },
    indCupo: {
      type: Number,
      default: 0,
    },
    indPrice: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    tipoContrato: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    totalCupos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    oficinas: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiDeleteOutline,
      },
      itemsOficinas: [],
      searchOficina: null,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
      pricesTemporadasContrateCar: state => state.app.pricesTemporadasContrateCar,
      cargandoCuposTemporadasContrateCar: state => state.app.cargandoCuposTemporadasContrateCar,
    }),
  },
  watch: {
    searchOficina(val) {
      if (val !== null && val.length > 1) {
        this.filterOficinas(val.toLowerCase())
      }
    },
  },
  methods: {
    ...mapMutations(['addCupoPricesTemporadasContrateCar', 'deleteCupoPricesTemporadasContrateCar']),
    filterOficinas(v) {
      this.itemsOficinas = []
      if (v === '') {
        this.itemsOficinas = []
      } else {
        this.itemsOficinas.push({
          id: -1,
          name: 'Todas',
        })
        const rrr = this.oficinas.filter(e => e.name.toLowerCase())
        rrr.forEach(element => {
          this.itemsOficinas.push(element)
        })
      }
    },
  },
}
</script>
