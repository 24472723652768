var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"label":_vm.$t('lbl.from'),"persistent-hint":"","readonly":"","outlined":"","dense":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.computedFromDateFormatted),callback:function ($$v) {_vm.computedFromDateFormatted=$$v},expression:"computedFromDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":new Date(Date.now()).toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFrom = false
          _vm.activeToDate()}},model:{value:(_vm.suplementoCar.from),callback:function ($$v) {_vm.$set(_vm.suplementoCar, "from", $$v)},expression:"suplementoCar.from"}})],1)],1),_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : '')},[(_vm.suplementoCar.from)?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"label":_vm.$t('lbl.to'),"persistent-hint":"","readonly":"","outlined":"","dense":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.computedToDateFormatted),callback:function ($$v) {_vm.computedToDateFormatted=$$v},expression:"computedToDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,3045805828),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","show-current":false,"min":_vm.$moment(_vm.suplementoCar.from)
            .add(1, 'day')
            .toDate()
            .toISOString()
            .substr(0, 10)},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.suplementoCar.to),callback:function ($$v) {_vm.$set(_vm.suplementoCar, "to", $$v)},expression:"suplementoCar.to"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }