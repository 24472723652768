<template>
  <fragment>
    <td
      :style="pos < suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''"
      colspan="2"
      class="text-rigth"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-select
            v-model="segurosEdadConductor.marca_id"
            :label="$t('lbl.brandModel')"
            :items="marcasModelo"
            class="mt-3"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-detailst
            multiple
            :disabled="$store.state.app.onlyShow"
          >
          </v-select>
          <!--<v-autocomplete
            v-model="segurosEdadConductor.category_id"
            :items="itemsMarcasModelo"
            :search-input.sync="searchCategory"
            hide-details
            :label="$t('menu.category')"
            outlined
            dense
            item-text="name"
            item-value="id"
            multiple
            clearable
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.category') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>-->
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-tooltip
            v-if="suplementoCar.seguros_edad_conductor.length > 1 && !$store.state.app.onlyShow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="error"
                class="mt-3"
                v-bind="attrs"
                v-on="on"
                @click="
                  deleteSeguroEdadConductorSuplementoContrateCar({
                    pos: pos,
                    indSuplementoCar: indSuplementoCar,
                  })
                "
              >
                <v-icon small>
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <v-tooltip
            v-if="pos === 0 && !$store.state.app.onlyShow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                x-small
                color="primary"
                v-bind="attrs"
                class="ml-2 mt-3"
                v-on="on"
                @click="
                  addSeguroEdadConductorSuplementoContrateCar({
                    indSuplementoCar: indSuplementoCar,
                    category_id: [-1],
                    marca_id: null,
                    edad_min: null,
                    edad_max: null,
                  })
                "
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </td>
    <td :style="pos < suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''">
      <v-text-field
        v-model="segurosEdadConductor.price"
        outlined
        dense
        hide-details
        type="number"
        prefix="$"
        :disabled="$store.state.app.onlyShow"
      ></v-text-field>
    </td>
    <td :style="pos < suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''">
      <v-text-field
        v-model="segurosEdadConductor.edad_min"
        :label="$t('lbl.menorDe')"
        outlined
        dense
        hide-details
        type="number"
        :suffix="$t('lbl.years')"
        :disabled="$store.state.app.onlyShow"
      ></v-text-field>
    </td>
    <td :style="pos < suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''">
      <v-text-field
        v-model="segurosEdadConductor.edad_max"
        :label="$t('lbl.mayorDe')"
        outlined
        dense
        hide-details
        type="number"
        :suffix="$t('lbl.years')"
        :disabled="$store.state.app.onlyShow"
      ></v-text-field>
    </td>
    <td :style="pos < suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''"></td>
    <td :style="pos < suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''"></td>
  </fragment>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiDeleteOutline, mdiMenuDown, mdiMenuRight, mdiPlus,
} from '@mdi/js'

export default {
  props: {
    pos: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    segurosEdadConductor: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplementoCar: {
      type: Object,
    },
    indSuplementoCar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
        mdiPlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
      marcasModelo: [],
      itemsMarcasModelo: [],
      searchCategory: null,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
    }),
  },
  watch: {
    searchCategory(val) {
      if (val !== null && val.length > 1) {
        this.filterCategory(val.toLowerCase())
      }
    },
  },
  created() {
    this.marcasModelo.push({
      id: -1,
      name: 'Todas',
    })
    // eslint-disable-next-line camelcase
    const cars_ids = []
    this.marcasModeloContrateCar.forEach(element => {
      // eslint-disable-next-line camelcase
      const cars_ii = element.cars_id
      cars_ii.forEach(elementCar => {
        if (!cars_ids.includes(elementCar.id)) {
          cars_ids.push(elementCar.id)
          this.marcasModelo.push({
            id: elementCar.id,
            name: elementCar.name,
          })
        }
      })
    })
  },
  methods: {
    ...mapMutations(['addSeguroEdadConductorSuplementoContrateCar', 'deleteSeguroEdadConductorSuplementoContrateCar']),
    filterCategory(v) {
      this.itemsMarcasModelo = []
      if (v === '') {
        this.itemsMarcasModelo = []
      } else {
        this.itemsMarcasModelo = this.marcasModelo.filter(e => e.name.toLowerCase())
      }
    },
  },
}
</script>
