<template>
  <tr>
    <td :style="indTarifa < total - 1 ? 'border: 0px solid black;' : ''"></td>
    <td
      :style="indTarifa < total - 1 ? 'border: 0px solid black;' : ''"
      colspan="5"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="1"
        >
          <v-checkbox
            v-model="model.check"
            hide-details
            class="my-0"
            :disabled="$store.state.app.onlyShow"
            @change="changeCheck()"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="11"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="4"
            >
              {{ tarifaXKm.comodin }}
            </v-col>
            <fragment v-if="model.check">
              <fragment
                v-for="(tarifaRetorno, indTarifaRetorno) in suplementoCar.tarifas_retorno"
                :key="indTarifaRetorno"
              >
                <fragment v-if="tarifaRetorno.tarifa_id === tarifaXKm.id">
                  <fragment
                    v-for="(tarifaPrice, indTarifaPrice) in tarifaRetorno.tarifa_prices"
                    :key="indTarifaPrice"
                  >
                    <fragment
                      v-for="(tarifaId, indTarifaId) in tarifasAll"
                      :key="indTarifaId"
                    >
                      <v-col
                        v-if="tarifaId.id === tarifaPrice.tarifa_id"
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="tarifaPrice.price"
                          :label="`${tarifaId.name} ${$t('lbl.day')} ($/Km)`"
                          outlined
                          dense
                          hide-details
                          suffix=""
                          width="auto"
                          class="pr-2"
                          type="number"
                          :disabled="$store.state.app.onlyShow"
                        ></v-text-field>
                      </v-col>
                    </fragment>
                  </fragment>
                </fragment>
              </fragment>
            </fragment>
          </v-row>
        </v-col>
      </v-row>
    </td>
  </tr>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'

export default {
  props: {
    indTarifa: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplementoCar: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    tarifaXKm: {
      type: Object,
    },
    indSuplementoCar: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    tarifasAll: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      suplementosContrateCar: state => state.app.suplementosContrateCar,
    }),
  },

  mounted() {
    this.suplementosContrateCar[this.indSuplementoCar].tarifas_retorno.forEach(element => {
      if (element.tarifa_id === this.tarifaXKm.id) {
        this.model.check = true
      }
    })
  },
  methods: {
    ...mapMutations(['addTarifaXKmSuplementoContrateCar', 'deleteTarifaXKmSuplementoContrateCar']),
    changeCheck() {
      if (this.model.check) {
        const prices = []
        this.suplementoCar.tarifas_id.forEach(element => {
          prices.push({ tarifa_id: element, price: 0 })
        })
        this.addTarifaXKmSuplementoContrateCar({
          indSuplementoCar: this.indSuplementoCar,
          tarifa_id: this.tarifaXKm.id,
          tarifa_prices: prices,
        })
      } else {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.suplementosContrateCar[this.indSuplementoCar].tarifas_retorno.length;
          // eslint-disable-next-line no-plusplus
          index++
        ) {
          const element = this.suplementosContrateCar[this.indSuplementoCar].tarifas_retorno[index]

          if (element.tarifa_id === this.tarifaXKm.id) {
            this.deleteTarifaXKmSuplementoContrateCar({ pos: index, indSuplementoCar: this.indSuplementoCar })
            break
          }
        }
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
