var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('tr',[_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-checkbox',{staticClass:"my-0",attrs:{"hide-details":"","disabled":_vm.checkSeguro || _vm.$store.state.app.onlyShow},on:{"change":function($event){return _vm.changeCheck()}},model:{value:(_vm.model.check),callback:function ($$v) {_vm.$set(_vm.model, "check", $$v)},expression:"model.check"}})],1),_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : '')},[_vm._v(" "+_vm._s(_vm.suplemento.name)+" "),(
          (_vm.suplemento.slug === 'tarifa-de-retorno' ||
            _vm.suplemento.slug === 'tanque-de-combustible-lleno' ||
            _vm.suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
            _vm.suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
            _vm.suplemento.slug === 'seguro-extra-por-edad-x-servicio') &&
            _vm.model.check
        )?_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.showItem()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight))])],1):_vm._e()],1),(
        (_vm.suplemento.slug === 'tarifa-de-retorno' ||
          _vm.suplemento.slug === 'tanque-de-combustible-lleno' ||
          _vm.suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
          _vm.suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
          _vm.suplemento.slug === 'seguro-extra-por-edad-x-servicio') &&
          _vm.model.check
      )?_c('fragment',_vm._l((_vm.suplementosContrateCar),function(suplementoCar,indSuplementoCar){return _c('fragment',{key:indSuplementoCar},[(suplementoCar.suplemento_id === _vm.suplemento.id)?_c('fragment',[(_vm.suplemento.slug === 'tarifa-de-retorno')?_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : ''),attrs:{"colspan":"2"}},[_c('v-select',{staticClass:"col-6",attrs:{"items":_vm.tarifas,"item-value":"id","item-text":"name","label":_vm.$t('menu.tarifas'),"outlined":"","dense":"","hide-details":"","multiple":"","width":"auto","disabled":_vm.$store.state.app.onlyShow},on:{"change":function($event){return _vm.changeTarifasRetorno(suplementoCar.tarifas_id, indSuplementoCar)}},model:{value:(suplementoCar.tarifas_id),callback:function ($$v) {_vm.$set(suplementoCar, "tarifas_id", $$v)},expression:"suplementoCar.tarifas_id"}})],1):_vm._e()]):_vm._e()],1)}),1):_vm._e(),(_vm.suplemento.slug !== 'tarifa-de-retorno')?_c('fragment',[_c('td',[_vm._v(_vm._s(_vm.suplemento.modalidad))])]):_vm._e(),(_vm.model.check)?_c('fragment',_vm._l((_vm.suplementosContrateCar),function(suplementoCar,indSuplementoCar){return _c('fragment',{key:indSuplementoCar},[(suplementoCar.suplemento_id === _vm.suplemento.id)?_c('fragment',[(
              _vm.suplemento.slug !== 'tarifa-de-retorno' &&
                _vm.suplemento.slug !== 'tanque-de-combustible-lleno' &&
                _vm.suplemento.slug !== 'seguro-extra-por-edad-del-conductor' &&
                _vm.suplemento.slug !== 'seguro-extra-por-edad-x-dia' &&
                _vm.suplemento.slug !== 'seguro-extra-por-edad-x-servicio'
            )?_c('td',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","prefix":"$","type":"number","width":"auto","disabled":_vm.$store.state.app.onlyShow},model:{value:(suplementoCar.price),callback:function ($$v) {_vm.$set(suplementoCar, "price", $$v)},expression:"suplementoCar.price"}})],1):_vm._e(),(
              _vm.suplemento.slug === 'tanque-de-combustible-lleno' ||
                _vm.suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
                _vm.suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
                _vm.suplemento.slug === 'seguro-extra-por-edad-x-servicio'
            )?_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : ''),attrs:{"colspan":"1"}}):_vm._e(),_c('fragment',[_c('SuplementosDates',{attrs:{"show-row":_vm.showRow,"ind-suplemento":_vm.indSuplemento,"index-row":_vm.indexRow,"suplemento-car":suplementoCar}})],1),_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(suplementoCar.online),callback:function ($$v) {_vm.$set(suplementoCar, "online", $$v)},expression:"suplementoCar.online"}})],1),_c('td',{style:(_vm.showRow && _vm.indSuplemento === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(suplementoCar.obligatorio),callback:function ($$v) {_vm.$set(suplementoCar, "obligatorio", $$v)},expression:"suplementoCar.obligatorio"}})],1)],1):_vm._e()],1)}),1):_c('fragment',[_c('td'),_c('td'),_c('td'),_c('td'),_c('td')])],1),_vm._l((_vm.suplementosContrateCar),function(suplementoCar,indSuplementoCar){return _c('fragment',{key:indSuplementoCar},[(suplementoCar.suplemento_id === _vm.suplemento.id && _vm.suplemento.slug === 'tarifa-de-retorno')?_c('fragment',[(_vm.showRow && _vm.indSuplemento === _vm.indexRow)?_c('fragment',_vm._l((_vm.tarifasXKm),function(tarifaXKm,indXKm){return _c('SuplementoTarifaRetorno',{key:indXKm,attrs:{"ind-tarifa":indXKm,"total":_vm.tarifasXKm.length,"tarifa-x-km":tarifaXKm,"suplemento-car":suplementoCar,"ind-suplemento-car":indSuplementoCar,"tarifas-all":_vm.tarifas}})}),1):_vm._e()],1):(
        suplementoCar.suplemento_id === _vm.suplemento.id &&
          (_vm.suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
            _vm.suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
            _vm.suplemento.slug === 'seguro-extra-por-edad-x-servicio')
      )?_c('fragment',[(_vm.showRow && _vm.indSuplemento === _vm.indexRow)?_c('fragment',[(_vm.categoryContrateCar.length > 0)?_c('fragment',[_c('SuplementoEdadConductorCategory',{attrs:{"suplemento-car":suplementoCar,"ind-suplemento-car":indSuplementoCar}})],1):_vm._e(),(_vm.marcasModeloContrateCar.length > 0)?_c('fragment',[_c('SuplementoEdadConductorMarca',{attrs:{"suplemento-car":suplementoCar,"ind-suplemento-car":indSuplementoCar}})],1):_vm._e()],1):_vm._e()],1):(suplementoCar.suplemento_id === _vm.suplemento.id && _vm.suplemento.slug === 'tanque-de-combustible-lleno')?_c('fragment',[(_vm.showRow && _vm.indSuplemento === _vm.indexRow)?_c('fragment',[(_vm.categoryContrateCar.length > 0)?_c('fragment',_vm._l((_vm.categoryContrateCar),function(category,indCategory){return _c('SuplementoCombustibleCategory',{key:indCategory,attrs:{"ind-category":indCategory,"category":category,"suplemento-car":suplementoCar,"ind-suplemento-car":indSuplementoCar}})}),1):_vm._e(),(_vm.marcasModeloContrateCar.length > 0)?_c('fragment',_vm._l((_vm.marcasModeloContrateCar),function(marca,indMarca){return _c('SuplementoCombustibleMarca',{key:indMarca,attrs:{"ind-marca":indMarca,"marca":marca,"suplemento-car":suplementoCar,"ind-suplemento-car":indSuplementoCar}})}),1):_vm._e()],1):_vm._e()],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }