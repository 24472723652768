<template>
  <v-row>
    <v-col cols="12">
      <v-tabs :key="tabsKey">
        <v-tab
          v-for="(season, index) in temporadasContrateCar"
          :key="index"
          :href="`#tab-${index}`"
        >
          <fragment v-if="season.name">
            <!--{{ season.from | moment('DD/MMM/Y') }} - {{ season.to | moment('DD/MMM/Y') }}-->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="mt-1 mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ season.name }}
                </span>
              </template>
              <span>
                <span
                  v-for="(date, indDate) in season.dates"
                  :key="indDate"
                >
                  {{ date.from | moment('DD/MMM/Y') }} - {{ date.to | moment('DD/MMM/Y') }}
                  <br />
                </span>
              </span>
            </v-tooltip>
          </fragment>
        </v-tab>
        <v-tab-item
          v-for="(season, index) in temporadasContrateCar"
          :key="index"
          :value="'tab-' + index"
        >
          <v-card
            v-if="season.name"
            flat
            tile
          >
            <v-card-text>
              <!--<v-data-table
                :headers="getHeaders(index)"
                :items="getItems(index)"
                class="elevation-1"
              >
                <template v-slot:item="{ item }">
                  <tr style="height:60px;">
                    <td
                      v-for="n in getTarifasAmmount(index) + 2"
                      :key="n"
                    >
                      <v-text-field
                        v-if="n > 2"
                        style="width:60px;"
                        outlined
                        hide-details
                        :value="item[(n-1).toString()]"
                      ></v-text-field>
                      <b v-else>{{ item[(n-1).toString()] }}</b>
                    </td>
                  </tr>
                </template>
              </v-data-table>-->
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">
                        <span class="text-center">
                          {{ tipoContrato === 'marca_modelo' ? $t('lbl.car') : $t('lbl.cat') }}
                        </span>
                      </th>
                      <th class="text-uppercase">
                        {{ $t('lbl.seguro') }}
                      </th>
                      <th class="text-uppercase">
                        {{ $t('lbl.garantia') }}
                      </th>
                      <th class="text-uppercase">
                        {{ $t('lbl.dayExtra') }}
                      </th>
                      <th
                        width="10%"
                        class="text-uppercase"
                      >
                        {{ $t('lbl.cupo') }}
                      </th>
                      <th
                        v-for="(tarif, indTarif) in getTarifas(index)"
                        :key="indTarif"
                      >
                        <span v-if="tarif.name">
                          {{ tarif.name }}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!isLoading">
                    <fragment v-if="tipoContrato === 'categoria'">
                      <fragment
                        v-for="(category, indCategory) in categoryContrateCar"
                        :key="indCategory"
                      >
                        <tr>
                          <td :style="showRow && indCategory === indexRow ? 'border: 0px solid black;' : ''">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  style="cursor: pointer"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ category.category_id.name }}
                                </span>
                              </template>
                              <span>
                                <span
                                  v-for="(car, indCar) in category.cars_id"
                                  :key="indCar"
                                >
                                  {{ car.name }}<br />
                                </span>
                              </span>
                            </v-tooltip>
                          </td>

                          <!--SEGURO-->
                          <td :style="showRow && indCategory === indexRow ? 'border: 0px solid black;' : ''">
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <fragment>
                                  <v-text-field
                                    v-if="
                                      priceTarif.tarifa_id === 'seguro' &&
                                        price.category_id === category.category_id.id &&
                                        price.temporada_id === season.temporada_id
                                    "
                                    v-model="priceTarif.price"
                                    style="width:85px;"
                                    outlined
                                    :label="$t('lbl.seguro')"
                                    dense
                                    hide-details
                                    prefix="$"
                                    type="number"
                                    :disabled="$store.state.app.onlyShow"
                                  ></v-text-field>
                                </fragment>
                              </fragment>
                            </fragment>
                          </td>
                          <!--DEPOSITO-->
                          <td :style="showRow && indCategory === indexRow ? 'border: 0px solid black;' : ''">
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <fragment>
                                  <v-text-field
                                    v-if="
                                      priceTarif.tarifa_id === 'deposito' &&
                                        price.category_id === category.category_id.id &&
                                        price.temporada_id === season.temporada_id
                                    "
                                    v-model="priceTarif.price"
                                    style="width:85px;"
                                    outlined
                                    :label="$t('lbl.garantia')"
                                    dense
                                    hide-details
                                    prefix="$"
                                    type="number"
                                    :disabled="$store.state.app.onlyShow"
                                  ></v-text-field>
                                </fragment>
                              </fragment>
                            </fragment>
                          </td>
                          <!--DIA EXTRA-->
                          <td :style="showRow && indCategory === indexRow ? 'border: 0px solid black;' : ''">
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <fragment>
                                  <v-text-field
                                    v-if="
                                      priceTarif.tarifa_id === 'day_extra' &&
                                        price.category_id === category.category_id.id &&
                                        price.temporada_id === season.temporada_id
                                    "
                                    v-model="priceTarif.price"
                                    style="width:85px;"
                                    outlined
                                    :label="$t('lbl.dayExtra')"
                                    dense
                                    hide-details
                                    prefix="$"
                                    type="number"
                                    :disabled="$store.state.app.onlyShow"
                                  ></v-text-field>
                                </fragment>
                              </fragment>
                            </fragment>
                          </td>
                          <!--CUPOS-->
                          <td :style="showRow && indCategory === indexRow ? 'border: 0px solid black;' : ''">
                            <v-btn
                              icon
                              @click="showItem(indCategory)"
                            >
                              <v-icon>
                                {{ showRow && indCategory === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                              </v-icon>
                            </v-btn>
                          </td>
                          <!--TARIFAS-->
                          <td
                            v-for="(tarif, indTarif) in getTarifas(index)"
                            :key="indTarif"
                            :style="showRow && indCategory === indexRow ? 'border: 0px solid black;' : ''"
                          >
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <v-text-field
                                  v-if="
                                    priceTarif.tarifa_id === tarif.id &&
                                      price.category_id === category.category_id.id &&
                                      price.temporada_id === season.temporada_id
                                  "
                                  v-model="priceTarif.price"
                                  style="width:85px;"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                  type="number"
                                  :disabled="$store.state.app.onlyShow"
                                ></v-text-field>
                              </fragment>
                            </fragment>
                          </td>
                        </tr>
                        <fragment v-if="showRow && indCategory === indexRow">
                          <fragment
                            v-for="(price, indPrice) in pricesTemporadasContrateCar"
                            :key="indPrice"
                          >
                            <fragment
                              v-if="
                                price.category_id === category.category_id.id &&
                                  price.temporada_id === season.temporada_id
                              "
                            >
                              <fragment v-if="!cargandoCuposTemporadasContrateCar">
                                <tr
                                  v-for="(cupo, indCupo) in price.cupos"
                                  :key="indCupo"
                                >
                                  <PricesCupos
                                    :cupo="cupo"
                                    :ind-cupo="indCupo"
                                    :ind-price="indPrice"
                                    :tipo-contrato="tipoContrato"
                                    :total-cupos="price.cupos.length"
                                    :oficinas="oficinas"
                                  />
                                </tr>
                              </fragment>
                            </fragment>
                          </fragment>
                        </fragment>
                      </fragment>
                    </fragment>
                    <fragment v-else>
                      <fragment
                        v-for="(marca, indMarca) in marcasModeloContrateCar"
                        :key="indMarca"
                      >
                        <tr>
                          <td :style="showRow && indMarca === indexRow ? 'border: 0px solid black;' : ''">
                            <span
                              v-for="(car, indCar) in marca.cars_id"
                              :key="indCar"
                            > {{ car.name }}<br /> </span>
                          </td>
                          <!--SEGURO-->
                          <td :style="showRow && indMarca === indexRow ? 'border: 0px solid black;' : ''">
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <fragment>
                                  <v-text-field
                                    v-if="
                                      priceTarif.tarifa_id === 'seguro' &&
                                        price.marca_id === marca.marca_id &&
                                        price.temporada_id === season.temporada_id
                                    "
                                    v-model="priceTarif.price"
                                    style="width:85px;"
                                    outlined
                                    dense
                                    hide-details
                                    prefix="$"
                                    type="number"
                                    :disabled="$store.state.app.onlyShow"
                                  ></v-text-field>
                                </fragment>
                              </fragment>
                            </fragment>
                          </td>
                          <!--DEPOSITO-->
                          <td :style="showRow && indMarca === indexRow ? 'border: 0px solid black;' : ''">
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <fragment>
                                  <v-text-field
                                    v-if="
                                      priceTarif.tarifa_id === 'deposito' &&
                                        price.marca_id === marca.marca_id &&
                                        price.temporada_id === season.temporada_id
                                    "
                                    v-model="priceTarif.price"
                                    style="width:85px;"
                                    outlined
                                    dense
                                    hide-details
                                    prefix="$"
                                    type="number"
                                    :disabled="$store.state.app.onlyShow"
                                  ></v-text-field>
                                </fragment>
                              </fragment>
                            </fragment>
                          </td>
                          <!--DIA EXTRA-->
                          <td :style="showRow && indMarca === indexRow ? 'border: 0px solid black;' : ''">
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <fragment>
                                  <v-text-field
                                    v-if="
                                      priceTarif.tarifa_id === 'day_extra' &&
                                        price.marca_id === marca.marca_id &&
                                        price.temporada_id === season.temporada_id
                                    "
                                    v-model="priceTarif.price"
                                    style="width:85px;"
                                    outlined
                                    dense
                                    hide-details
                                    prefix="$"
                                    type="number"
                                    :disabled="$store.state.app.onlyShow"
                                  ></v-text-field>
                                </fragment>
                              </fragment>
                            </fragment>
                          </td>
                          <!--CUPOS-->
                          <td :style="showRow && indMarca === indexRow ? 'border: 0px solid black;' : ''">
                            <v-btn
                              icon
                              @click="showItem(indMarca)"
                            >
                              <v-icon>
                                {{ showRow && indMarca === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                              </v-icon>
                            </v-btn>
                          </td>
                          <!--TARIFAS-->
                          <td
                            v-for="(tarif, indTarif) in getTarifas(index)"
                            :key="indTarif"
                            :style="showRow && indMarca === indexRow ? 'border: 0px solid black;' : ''"
                          >
                            <fragment
                              v-for="(price, indPrice) in pricesTemporadasContrateCar"
                              :key="indPrice"
                            >
                              <fragment
                                v-for="(priceTarif, indPriceTarif) in price.prices"
                                :key="indPriceTarif"
                              >
                                <v-text-field
                                  v-if="
                                    priceTarif.tarifa_id === tarif.id &&
                                      price.marca_id === marca.marca_id &&
                                      price.temporada_id === season.temporada_id
                                  "
                                  v-model="priceTarif.price"
                                  style="width:85px;"
                                  outlined
                                  dense
                                  hide-details
                                  prefix="$"
                                  type="number"
                                  :disabled="$store.state.app.onlyShow"
                                ></v-text-field>
                              </fragment>
                            </fragment>
                          </td>
                        </tr>
                        <fragment v-if="showRow && indMarca === indexRow">
                          <fragment
                            v-for="(price, indPrice) in pricesTemporadasContrateCar"
                            :key="indPrice"
                          >
                            <fragment
                              v-if="price.marca_id === marca.marca_id && price.temporada_id === season.temporada_id"
                            >
                              <fragment v-if="!cargandoCuposTemporadasContrateCar">
                                <tr
                                  v-for="(cupo, indCupo) in price.cupos"
                                  :key="indCupo"
                                >
                                  <PricesCupos
                                    :cupo="cupo"
                                    :ind-cupo="indCupo"
                                    :ind-price="indPrice"
                                    :tipo-contrato="tipoContrato"
                                    :total-cupos="price.cupos.length"
                                    :oficinas="oficinas"
                                  />
                                </tr>
                              </fragment>
                            </fragment>
                          </fragment>
                        </fragment>
                      </fragment>
                    </fragment>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'

import PricesCupos from './PricesCupos.vue'

export default {
  components: {
    PricesCupos,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tipoContrato: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    oficinas: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      isLoading: true,
      allTarifas: [],
      categories: {},
      brands: {},
      tabsKey: 0,
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    validSeasons() {
      return this.seasons.filter(s => s.from !== null && s.to !== null && s.tarifas.length > 0)
    },

    ...mapState({
      onlyShow: state => state.app.onlyShow,
      cargandoCuposTemporadasContrateCar: state => state.app.cargandoCuposTemporadasContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
      temporadasContrateCar: state => state.app.temporadasContrateCar,
      pricesTemporadasContrateCar: state => state.app.pricesTemporadasContrateCar,
    }),
  },
  created() {
    this.getAllTarifas()
  },

  methods: {
    ...mapMutations([
      'addCategoryContrateCar',
      'updateCategoryContrateCar',
      'addMarcaModeloContrateCar',
      'updateMarcaModeloContrateCar',
      'addTemporadaContrateCar',
    ]),
    getAllTarifas() {
      this.axios
        .get('nom_tarifas_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.allTarifas = res.data.data
          }
        })
    },

    getTarifas(seasonIndex) {
      const ids = this.temporadasContrateCar[seasonIndex].tarifas_id
      const tarifas = []
      ids.forEach(id => {
        if (this.allTarifas.filter(t => t.id === id).length > 0) {
          tarifas.push(this.allTarifas.filter(t => t.id === id)[0])
        }
      })

      setTimeout(() => {
        this.isLoading = false
      }, 100)

      return tarifas
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>
