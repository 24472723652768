var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-6 mx-1"},[_c('v-col',{staticStyle:{"border":"1px solid #dbdade","border-radius":"5px"},attrs:{"cols":"11"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.cat')))]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.itemsCategories,"search-input":_vm.searchCategory,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.cat'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":"","return-object":"","disabled":_vm.$store.state.app.onlyShow},on:{"update:searchInput":function($event){_vm.searchCategory=$event},"update:search-input":function($event){_vm.searchCategory=$event},"change":_vm.verifyPricesTemporadasContrateCar},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.cat'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.cat.category_id),callback:function ($$v) {_vm.$set(_vm.cat, "category_id", $$v)},expression:"cat.category_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.cars,"label":_vm.$t('menu.modelos'),"outlined":"","hide-details":"","dense":"","chips":"","small-chips":"","multiple":"","item-text":"name","item-value":"id","return-object":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.cat.cars_id),callback:function ($$v) {_vm.$set(_vm.cat, "cars_id", $$v)},expression:"cat.cars_id"}})],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"pt-2",attrs:{"label":_vm.$t('lbl.edadConductor'),"items":_vm.edades,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-detailst":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.cat.edad_id),callback:function ($$v) {_vm.$set(_vm.cat, "edad_id", $$v)},expression:"cat.edad_id"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-row',{staticClass:"my-0",staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"px-4"},[(!_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteCategoryContrateCar(_vm.index)}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,false,3190157256)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }