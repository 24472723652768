<template>
  <fragment>
    <tr>
      <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
        <v-checkbox
          v-model="model.check"
          hide-details
          class="my-0"
          :disabled="checkSeguro || $store.state.app.onlyShow"
          @change="changeCheck()"
        ></v-checkbox>
      </td>
      <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
        {{ suplemento.name }}
        <v-btn
          v-if="
            (suplemento.slug === 'tarifa-de-retorno' ||
              suplemento.slug === 'tanque-de-combustible-lleno' ||
              suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
              suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
              suplemento.slug === 'seguro-extra-por-edad-x-servicio') &&
              model.check
          "
          icon
          class="ml-5"
          @click="showItem()"
        >
          <v-icon>{{ showRow && indSuplemento === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}</v-icon>
        </v-btn>
      </td>

      <fragment
        v-if="
          (suplemento.slug === 'tarifa-de-retorno' ||
            suplemento.slug === 'tanque-de-combustible-lleno' ||
            suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
            suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
            suplemento.slug === 'seguro-extra-por-edad-x-servicio') &&
            model.check
        "
      >
        <fragment
          v-for="(suplementoCar, indSuplementoCar) in suplementosContrateCar"
          :key="indSuplementoCar"
        >
          <fragment v-if="suplementoCar.suplemento_id === suplemento.id">
            <td
              v-if="suplemento.slug === 'tarifa-de-retorno'"
              :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''"
              colspan="2"
            >
              <v-select
                v-model="suplementoCar.tarifas_id"
                :items="tarifas"
                item-value="id"
                item-text="name"
                :label="$t('menu.tarifas')"
                outlined
                dense
                hide-details
                multiple
                width="auto"
                class="col-6"
                :disabled="$store.state.app.onlyShow"
                @change="changeTarifasRetorno(suplementoCar.tarifas_id, indSuplementoCar)"
              >
              </v-select>
            </td>
          </fragment>
        </fragment>
      </fragment>
      <!--&& suplemento.slug !== 'tanque de combustible lleno'-->
      <fragment v-if="suplemento.slug !== 'tarifa-de-retorno'">
        <td>{{ suplemento.modalidad }}</td>
      </fragment>

      <fragment v-if="model.check">
        <fragment
          v-for="(suplementoCar, indSuplementoCar) in suplementosContrateCar"
          :key="indSuplementoCar"
        >
          <fragment v-if="suplementoCar.suplemento_id === suplemento.id">
            <td
              v-if="
                suplemento.slug !== 'tarifa-de-retorno' &&
                  suplemento.slug !== 'tanque-de-combustible-lleno' &&
                  suplemento.slug !== 'seguro-extra-por-edad-del-conductor' &&
                  suplemento.slug !== 'seguro-extra-por-edad-x-dia' &&
                  suplemento.slug !== 'seguro-extra-por-edad-x-servicio'
              "
            >
              <v-text-field
                v-model="suplementoCar.price"
                outlined
                dense
                hide-details
                prefix="$"
                type="number"
                width="auto"
                :disabled="$store.state.app.onlyShow"
              ></v-text-field>
            </td>
            <td
              v-if="
                suplemento.slug === 'tanque-de-combustible-lleno' ||
                  suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
                  suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
                  suplemento.slug === 'seguro-extra-por-edad-x-servicio'
              "
              :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''"
              colspan="1"
            ></td>

            <!--VALIDEZ-->
            <fragment>
              <SuplementosDates
                :show-row="showRow"
                :ind-suplemento="indSuplemento"
                :index-row="indexRow"
                :suplemento-car="suplementoCar"
              />
            </fragment>
            <!--<td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <vc-date-picker
                v-model="suplementoCar.from"
                outlined
                dense
                :min-date="new Date()"
                mode="date"
                :model-config="modelConfig"
                width="auto"
                @input="activeToDate"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :label="$t('lbl.from')"
                    outlined
                    dense
                    :value="inputValue"
                    class="mt-5"
                    v-on="inputEvents"
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </td>
            <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <vc-date-picker
                v-if="suplementoCar.from"
                v-model="suplementoCar.to"
                outlined
                dense
                :min-date="
                  $moment(suplementoCar.from)
                    .add(1, 'day')
                    .toDate()
                "
                mode="date"
                width="auto"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    ref="dateTo"
                    :label="$t('lbl.to')"
                    outlined
                    dense
                    :value="inputValue"
                    class="mt-5"
                    v-on="inputEvents"
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </td>-->
            <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <v-switch
                v-model="suplementoCar.online"
                hide-details
                class="mt-0"
                :disabled="$store.state.app.onlyShow"
              ></v-switch>
            </td>
            <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <v-switch
                v-model="suplementoCar.obligatorio"
                hide-details
                class="mt-0"
                :disabled="$store.state.app.onlyShow"
              ></v-switch>
            </td>
          </fragment>
        </fragment>
      </fragment>
      <fragment v-else>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </fragment>

      <!---->
    </tr>

    <fragment
      v-for="(suplementoCar, indSuplementoCar) in suplementosContrateCar"
      :key="indSuplementoCar"
    >
      <fragment v-if="suplementoCar.suplemento_id === suplemento.id && suplemento.slug === 'tarifa-de-retorno'">
        <fragment v-if="showRow && indSuplemento === indexRow">
          <SuplementoTarifaRetorno
            v-for="(tarifaXKm, indXKm) in tarifasXKm"
            :key="indXKm"
            :ind-tarifa="indXKm"
            :total="tarifasXKm.length"
            :tarifa-x-km="tarifaXKm"
            :suplemento-car="suplementoCar"
            :ind-suplemento-car="indSuplementoCar"
            :tarifas-all="tarifas"
          />
        </fragment>
      </fragment>
      <fragment
        v-else-if="
          suplementoCar.suplemento_id === suplemento.id &&
            (suplemento.slug === 'seguro-extra-por-edad-del-conductor' ||
              suplemento.slug === 'seguro-extra-por-edad-x-dia' ||
              suplemento.slug === 'seguro-extra-por-edad-x-servicio')
        "
      >
        <fragment v-if="showRow && indSuplemento === indexRow">
          <fragment v-if="categoryContrateCar.length > 0">
            <SuplementoEdadConductorCategory
              :suplemento-car="suplementoCar"
              :ind-suplemento-car="indSuplementoCar"
            />
          </fragment>
          <fragment v-if="marcasModeloContrateCar.length > 0">
            <SuplementoEdadConductorMarca
              :suplemento-car="suplementoCar"
              :ind-suplemento-car="indSuplementoCar"
            />
          </fragment>
          <!--<fragment v-if="marcasModeloContrateCar.length > 0">
            <SuplementoCombustibleMarca
              v-for="(marca, indMarca) in marcasModeloContrateCar"
              :key="indMarca"
              :ind-marca="indMarca"
              :marca="marca"
              :suplemento-car="suplementoCar"
              :ind-suplemento-car="indSuplementoCar"
            />
          </fragment>-->
        </fragment>
      </fragment>

      <fragment
        v-else-if="suplementoCar.suplemento_id === suplemento.id && suplemento.slug === 'tanque-de-combustible-lleno'"
      >
        <fragment v-if="showRow && indSuplemento === indexRow">
          <fragment v-if="categoryContrateCar.length > 0">
            <SuplementoCombustibleCategory
              v-for="(category, indCategory) in categoryContrateCar"
              :key="indCategory"
              :ind-category="indCategory"
              :category="category"
              :suplemento-car="suplementoCar"
              :ind-suplemento-car="indSuplementoCar"
            />
          </fragment>
          <fragment v-if="marcasModeloContrateCar.length > 0">
            <SuplementoCombustibleMarca
              v-for="(marca, indMarca) in marcasModeloContrateCar"
              :key="indMarca"
              :ind-marca="indMarca"
              :marca="marca"
              :suplemento-car="suplementoCar"
              :ind-suplemento-car="indSuplementoCar"
            />
          </fragment>
        </fragment>
      </fragment>
    </fragment>
  </fragment>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'

import SuplementoTarifaRetorno from './SuplementoTarifaRetorno.vue'
import SuplementoEdadConductorCategory from './SuplementoEdadConductorCategory.vue'
import SuplementoEdadConductorMarca from './SuplementoEdadConductorMarca.vue'
import SuplementoCombustibleCategory from './SuplementoCombustibleCategory.vue'
import SuplementoCombustibleMarca from './SuplementoCombustibleMarca.vue'
import SuplementosDates from './SuplementosDates.vue'

export default {
  components: {
    SuplementoTarifaRetorno,
    SuplementoEdadConductorCategory,
    SuplementoEdadConductorMarca,
    SuplementoCombustibleCategory,
    SuplementoCombustibleMarca,
    SuplementosDates,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplemento: {
      type: Object,
    },
    indSuplemento: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplementosAll: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
      tarifas: [],
      tarifasXKm: [],
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
    }),
    checkSeguro() {
      let disabled = false
      let idSeguroExtraDay = 0
      let idSeguroExtraServ = 0
      if (
        this.suplemento.slug === 'seguro-extra-por-edad-del-conductor'
        || this.suplemento.slug === 'seguro-extra-por-edad-x-dia'
      ) {
        if (this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-x-servicio').length > 0) {
          idSeguroExtraServ = this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-x-servicio')[0].id
        }

        this.suplementosContrateCar.forEach(element => {
          if (element.suplemento_id === idSeguroExtraServ) {
            disabled = true
          }
        })
      } else if (this.suplemento.slug === 'seguro-extra-por-edad-x-servicio') {
        if (
          this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-x-dia').length > 0
          || this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-del-conductor').length > 0
        ) {
          if (this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-x-dia').length > 0) {
            idSeguroExtraDay = this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-x-dia')[0].id
          } else if (this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-del-conductor').length > 0) {
            idSeguroExtraDay = this.suplementosAll.filter(e => e.slug === 'seguro-extra-por-edad-del-conductor')[0].id
          }
        }
        this.suplementosContrateCar.forEach(element => {
          if (element.suplemento_id === idSeguroExtraDay) {
            disabled = true
          }
        })
      }

      return disabled
    },
  },
  mounted() {
    this.suplementosContrateCar.forEach(element => {
      if (element.suplemento_id === this.suplemento.id) {
        this.model.check = true
      }
    })
  },
  created() {
    this.getTarifas()
    this.getTarifasXKm()
  },
  methods: {
    ...mapMutations([
      'addSuplementoContrateCar',
      'deleteSuplementoContrateCar',
      'addPriceTarifaXKmSuplementoContrateCar',
      'deletePriceTarifaXKmSuplementoContrateCar',
    ]),
    activeToDate() {
      setTimeout(() => {
        this.$refs.dateTo[0].focus()
      }, 100)
    },
    changeCheck() {
      if (this.model.check) {
        this.addSuplementoContrateCar({
          from: null,
          to: null,
          suplemento_id: this.suplemento.id,
          price: 0,
          obligatorio: false,
          tarifas_id: [],
          tarifas_retorno: [],
          tarifas_combustible: [],
          seguros_edad_conductor: [
            {
              category_id: [-1],
              marca_id: null,
              edad_min: null,
              edad_max: null,
            },
          ],
        })
      } else {
        this.showRow = !this.showRow
        this.indexRow = -1
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.suplementosContrateCar.length; index++) {
          const element = this.suplementosContrateCar[index]

          if (element.suplemento_id === this.suplemento.id) {
            this.deleteSuplementoContrateCar(index)
            break
          }
        }
      }
    },
    showItem() {
      if (this.indexRow === this.indSuplemento) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = this.indSuplemento
    },
    getTarifas() {
      this.axios
        .get('nom_tarifas_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.tarifas = res.data.data
          }
        })
    },
    getTarifasXKm() {
      this.axios
        .get('nom_tarifas_x_km_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.tarifasXKm = res.data.data
          }
        })
    },
    changeTarifasRetorno(tarifas, indSuplementoCar) {
      // PARA DETECTAR CUANDO SE ELIMINO UNA TARIFA
      // eslint-disable-next-line no-plusplus
      for (
        let indexTarifaReto = 0;
        indexTarifaReto < this.suplementosContrateCar[indSuplementoCar].tarifas_retorno.length;
        // eslint-disable-next-line no-plusplus
        indexTarifaReto++
      ) {
        const element = this.suplementosContrateCar[indSuplementoCar].tarifas_retorno[indexTarifaReto]
        const prices = element.tarifa_prices

        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < prices.length; index++) {
          let yaEsta = false
          const price = prices[index]

          if (tarifas.includes(price.tarifa_id)) {
            yaEsta = true
          }

          if (!yaEsta) {
            this.deletePriceTarifaXKmSuplementoContrateCar({
              pos: index,
              indSuplementoCar,
              indTarifaRetorno: indexTarifaReto,
            })
          }
        }
      }

      // PARA DETECTAR CUANDO SE AGREGO UNA TARIFA
      tarifas.forEach(tarifa => {
        // eslint-disable-next-line no-plusplus
        for (
          let indexTarifaReto = 0;
          indexTarifaReto < this.suplementosContrateCar[indSuplementoCar].tarifas_retorno.length;
          // eslint-disable-next-line no-plusplus
          indexTarifaReto++
        ) {
          const element = this.suplementosContrateCar[indSuplementoCar].tarifas_retorno[indexTarifaReto]
          const prices = element.tarifa_prices

          let yaEstaTari = false
          // eslint-disable-next-line no-plusplus
          for (let indexP = 0; indexP < prices.length; indexP++) {
            const price = prices[indexP]

            if (tarifa === price.tarifa_id) {
              yaEstaTari = true
            }
          }

          if (!yaEstaTari) {
            this.addPriceTarifaXKmSuplementoContrateCar({
              indSuplementoCar,
              indTarifaRetorno: indexTarifaReto,
              tarifa_id: tarifa,
              price: 0,
            })
          }
        }
      })
    },
  },
}
</script>
