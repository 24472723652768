var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('td',{style:(_vm.indCupo < _vm.totalCupos - 1 ? 'border: 0px solid black;' : '')}),_c('td',{style:(_vm.indCupo < _vm.totalCupos - 1 ? 'border: 0px solid black;' : ''),attrs:{"colspan":_vm.tipoContrato === 'categoria' ? 2 : 2}},[_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.oficinas,"search-input":_vm.searchOficina,"hide-details":"","label":_vm.$t('menu.oficinaRenta'),"outlined":"","dense":"","item-text":"name","item-value":"id","multiple":"","clearable":"","disabled":_vm.$store.state.app.onlyShow},on:{"update:searchInput":function($event){_vm.searchOficina=$event},"update:search-input":function($event){_vm.searchOficina=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('menu.oficinaRenta'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.cupo.oficina_id),callback:function ($$v) {_vm.$set(_vm.cupo, "oficina_id", $$v)},expression:"cupo.oficina_id"}})],1),_c('td',{style:(_vm.indCupo < _vm.totalCupos - 1 ? 'border: 0px solid black;' : '')},[_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","dense":"","hide-details":"","label":_vm.cupo.cuposDisponible ? _vm.$t('lbl.cupo') : '',"placeholder":"OR","type":"number","min":"0","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.cupo.cuposDisponible),callback:function ($$v) {_vm.$set(_vm.cupo, "cuposDisponible", $$v)},expression:"cupo.cuposDisponible"}})],1),_c('td',{style:(_vm.indCupo < _vm.totalCupos - 1 ? 'border: 0px solid black;' : '')},[(_vm.totalCupos > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteCupoPricesTemporadasContrateCar({ posPrice: _vm.indPrice, posCupo: _vm.indCupo })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,false,3165504599)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e(),(_vm.indCupo === 0 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addCupoPricesTemporadasContrateCar(_vm.indPrice)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,1774131599)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }