<template>
  <v-row class="my-6 mx-1">
    <v-col
      cols="11"
      style="border:1px solid #dbdade; border-radius:5px;"
    >
      <v-row>
        <!--<v-col cols="6">
          <strong>{{ $t('lbl.from') }}</strong>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFromFormatted"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="from"
              no-title
              scrollable
              :max="getMaxDate()"
              @click:date="menu=false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <strong>{{ $t('lbl.to') }}</strong>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="309"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedToFormatted"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="to"
              no-title
              scrollable
              :min="getMinDate()"
              @click:date="menu2=false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>-->
        <v-col
          cols="12"
          sm="10"
          md="10"
        >
          <v-text-field
            v-model="season.name"
            :label="$t('lbl.name')"
            outlined
            dense
            hide-details
            :disabled="$store.state.app.onlyShow"
            @input="verifyPricesTemporadasContrateCar"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="1"
          md="1"
        >
          <v-tooltip
            v-if="index === 0 && !$store.state.app.onlyShow"
            top
            class="text-right"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1"
                fab
                dark
                x-small
                color="primary"
                @click="addTemporadaContrateCar()"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <CarsSeasonDates
            v-for="(date, indDate) in season.dates"
            :key="indDate"
            :ind-date="indDate"
            :ind-season="index"
            :date="date"
            :total="season.dates.length"
          />
        </v-col>

        <v-col
          cols="12"
          sm="10"
          md="10"
        >
          <v-select
            v-model="season.tarifas_id"
            class="pt-2"
            :items="tarifas"
            item-value="id"
            item-text="name"
            :label="$t('lbl.rangeDaysRent')"
            outlined
            dense
            hide-details
            multiple
            :disabled="$store.state.app.onlyShow"
            @change="verifyPricesTemporadasContrateCar"
          >
          </v-select>
        </v-col>
        <v-col
          cols="12"
          sm="10"
          md="10"
        >
          <v-text-field
            v-model="season.release"
            label="Release"
            outlined
            dense
            hide-details
            type="number"
            :suffix="$t('lbl.day')"
            :disabled="$store.state.app.onlyShow"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1">
      <v-row
        class="my-0"
        align="center"
        justify="center"
        style="height:100%;"
      >
        <v-tooltip
          v-if="!$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              @click="deleteTemporadaContrateCar(index)"
            >
              <v-icon
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiPlus } from '@mdi/js'
import CarsSeasonDates from './CarsSeasonDates.vue'

export default {
  components: {
    CarsSeasonDates,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    season: {
      type: Object,
    },
    index: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    tipoContrato: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiPlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      menu: false,
      menu2: false,
      from: null,
      to: null,
      tarifas: [],
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
      temporadasContrateCar: state => state.app.temporadasContrateCar,
    }),
  },
  created() {
    this.getTarifas()
  },
  methods: {
    ...mapMutations(['addTemporadaContrateCar', 'verifyPricesTemporadasContrateCar', 'deleteTemporadaContrateCar']),
    getTarifaText(tarifa) {
      return tarifa.min !== null && tarifa.max !== null
        ? `${tarifa.min} ${this.$t('lbl.a')} ${tarifa.max}`
        : `${tarifa.min}+`
    },
    getTarifas() {
      this.axios
        .get('nom_tarifas_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.tarifas = res.data.data
          }
        })
    },
  },
}
</script>
