var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{key:_vm.tabsKey},[_vm._l((_vm.temporadasContrateCar),function(season,index){return _c('v-tab',{key:index,attrs:{"href":("#tab-" + index)}},[(season.name)?_c('fragment',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mt-1 mr-2"},'span',attrs,false),on),[_vm._v(" "+_vm._s(season.name)+" ")])]}}],null,true)},[_c('span',_vm._l((season.dates),function(date,indDate){return _c('span',{key:indDate},[_vm._v(" "+_vm._s(_vm._f("moment")(date.from,'DD/MMM/Y'))+" - "+_vm._s(_vm._f("moment")(date.to,'DD/MMM/Y'))+" "),_c('br')])}),0)])],1):_vm._e()],1)}),_vm._l((_vm.temporadasContrateCar),function(season,index){return _c('v-tab-item',{key:index,attrs:{"value":'tab-' + index}},[(season.name)?_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.tipoContrato === 'marca_modelo' ? _vm.$t('lbl.car') : _vm.$t('lbl.cat'))+" ")])]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.seguro'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.garantia'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.dayExtra'))+" ")]),_c('th',{staticClass:"text-uppercase",attrs:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.cupo'))+" ")]),_vm._l((_vm.getTarifas(index)),function(tarif,indTarif){return _c('th',{key:indTarif},[(tarif.name)?_c('span',[_vm._v(" "+_vm._s(tarif.name)+" ")]):_vm._e()])})],2)]),(!_vm.isLoading)?_c('tbody',[(_vm.tipoContrato === 'categoria')?_c('fragment',_vm._l((_vm.categoryContrateCar),function(category,indCategory){return _c('fragment',{key:indCategory},[_c('tr',[_c('td',{style:(_vm.showRow && indCategory === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(category.category_id.name)+" ")])]}}],null,true)},[_c('span',_vm._l((category.cars_id),function(car,indCar){return _c('span',{key:indCar},[_vm._v(" "+_vm._s(car.name)),_c('br')])}),0)])],1),_c('td',{style:(_vm.showRow && indCategory === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[_c('fragment',[(
                                    priceTarif.tarifa_id === 'seguro' &&
                                      price.category_id === category.category_id.id &&
                                      price.temporada_id === season.temporada_id
                                  )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","label":_vm.$t('lbl.seguro'),"dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)],1)}),1)}),1),_c('td',{style:(_vm.showRow && indCategory === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[_c('fragment',[(
                                    priceTarif.tarifa_id === 'deposito' &&
                                      price.category_id === category.category_id.id &&
                                      price.temporada_id === season.temporada_id
                                  )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","label":_vm.$t('lbl.garantia'),"dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)],1)}),1)}),1),_c('td',{style:(_vm.showRow && indCategory === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[_c('fragment',[(
                                    priceTarif.tarifa_id === 'day_extra' &&
                                      price.category_id === category.category_id.id &&
                                      price.temporada_id === season.temporada_id
                                  )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","label":_vm.$t('lbl.dayExtra'),"dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)],1)}),1)}),1),_c('td',{style:(_vm.showRow && indCategory === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showItem(indCategory)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showRow && indCategory === _vm.indexRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])],1)],1),_vm._l((_vm.getTarifas(index)),function(tarif,indTarif){return _c('td',{key:indTarif,style:(_vm.showRow && indCategory === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[(
                                  priceTarif.tarifa_id === tarif.id &&
                                    price.category_id === category.category_id.id &&
                                    price.temporada_id === season.temporada_id
                                )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)}),1)}),1)})],2),(_vm.showRow && indCategory === _vm.indexRow)?_c('fragment',_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},[(
                              price.category_id === category.category_id.id &&
                                price.temporada_id === season.temporada_id
                            )?_c('fragment',[(!_vm.cargandoCuposTemporadasContrateCar)?_c('fragment',_vm._l((price.cupos),function(cupo,indCupo){return _c('tr',{key:indCupo},[_c('PricesCupos',{attrs:{"cupo":cupo,"ind-cupo":indCupo,"ind-price":indPrice,"tipo-contrato":_vm.tipoContrato,"total-cupos":price.cupos.length,"oficinas":_vm.oficinas}})],1)}),0):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)}),1):_c('fragment',_vm._l((_vm.marcasModeloContrateCar),function(marca,indMarca){return _c('fragment',{key:indMarca},[_c('tr',[_c('td',{style:(_vm.showRow && indMarca === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((marca.cars_id),function(car,indCar){return _c('span',{key:indCar},[_vm._v(" "+_vm._s(car.name)),_c('br')])}),0),_c('td',{style:(_vm.showRow && indMarca === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[_c('fragment',[(
                                    priceTarif.tarifa_id === 'seguro' &&
                                      price.marca_id === marca.marca_id &&
                                      price.temporada_id === season.temporada_id
                                  )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)],1)}),1)}),1),_c('td',{style:(_vm.showRow && indMarca === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[_c('fragment',[(
                                    priceTarif.tarifa_id === 'deposito' &&
                                      price.marca_id === marca.marca_id &&
                                      price.temporada_id === season.temporada_id
                                  )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)],1)}),1)}),1),_c('td',{style:(_vm.showRow && indMarca === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[_c('fragment',[(
                                    priceTarif.tarifa_id === 'day_extra' &&
                                      price.marca_id === marca.marca_id &&
                                      price.temporada_id === season.temporada_id
                                  )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)],1)}),1)}),1),_c('td',{style:(_vm.showRow && indMarca === _vm.indexRow ? 'border: 0px solid black;' : '')},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showItem(indMarca)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showRow && indMarca === _vm.indexRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])],1)],1),_vm._l((_vm.getTarifas(index)),function(tarif,indTarif){return _c('td',{key:indTarif,style:(_vm.showRow && indMarca === _vm.indexRow ? 'border: 0px solid black;' : '')},_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},_vm._l((price.prices),function(priceTarif,indPriceTarif){return _c('fragment',{key:indPriceTarif},[(
                                  priceTarif.tarifa_id === tarif.id &&
                                    price.marca_id === marca.marca_id &&
                                    price.temporada_id === season.temporada_id
                                )?_c('v-text-field',{staticStyle:{"width":"85px"},attrs:{"outlined":"","dense":"","hide-details":"","prefix":"$","type":"number","disabled":_vm.$store.state.app.onlyShow},model:{value:(priceTarif.price),callback:function ($$v) {_vm.$set(priceTarif, "price", $$v)},expression:"priceTarif.price"}}):_vm._e()],1)}),1)}),1)})],2),(_vm.showRow && indMarca === _vm.indexRow)?_c('fragment',_vm._l((_vm.pricesTemporadasContrateCar),function(price,indPrice){return _c('fragment',{key:indPrice},[(price.marca_id === marca.marca_id && price.temporada_id === season.temporada_id)?_c('fragment',[(!_vm.cargandoCuposTemporadasContrateCar)?_c('fragment',_vm._l((price.cupos),function(cupo,indCupo){return _c('tr',{key:indCupo},[_c('PricesCupos',{attrs:{"cupo":cupo,"ind-cupo":indCupo,"ind-price":indPrice,"tipo-contrato":_vm.tipoContrato,"total-cupos":price.cupos.length,"oficinas":_vm.oficinas}})],1)}),0):_vm._e()],1):_vm._e()],1)}),1):_vm._e()],1)}),1)],1):_vm._e()]},proxy:true}],null,true)})],1)],1):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }