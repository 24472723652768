<template>
  <tr>
    <td :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
    <td
      :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''"
      colspan="2"
      class="text-rigth"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="1"
        >
          <v-checkbox
            v-model="model.check"
            hide-details
            class="my-0"
            :disabled="$store.state.app.onlyShow"
            @change="changeCheck()"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="11"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                >
                  {{ category.category_id.name }}

                  <v-tooltip
                    content-class="custom-tooltip"
                    top
                    max-width="250px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary">
                          mdi-information-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="caption">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <div class="d-block">
                            <div class="d-flex justify-start align-center">
                              <p class="mb-1 mt-2">
                                <span
                                  v-for="(carsI, inde) in category.cars_id"
                                  :key="inde"
                                >
                                  {{ carsI.name }}<br />
                                </span>
                              </p>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </td>
    <td :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''">
      <template v-if="model.check">
        <template v-for="(tarifaCombustible, indCombbb) in suplementoCar.tarifas_combustible">
          <template v-if="tarifaCombustible.category_id === category.category_id.id">
            <v-text-field
              :key="indCombbb"
              v-model="tarifaCombustible.price"
              :label="$t('lbl.price')"
              outlined
              dense
              hide-details
              prefix="$"
              type="number"
              width="auto"
              :disabled="$store.state.app.onlyShow"
            ></v-text-field>
          </template>
        </template>
      </template>
    </td>
    <td :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
    <td :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
    <td :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''"></td>
  </tr>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'

export default {
  props: {
    indCategory: {
      type: Number,
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop
    category: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplementoCar: {
      type: Object,
    },
    indSuplementoCar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
    }),
  },

  mounted() {
    this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible.forEach(element => {
      if (element.category_id === this.category.category_id.id) {
        this.model.check = true
      }
    })
  },
  methods: {
    ...mapMutations(['addTarifaCombustibleSuplementoContrateCar', 'deleteTarifaCombustibleSuplementoContrateCar']),
    changeCheck() {
      if (this.model.check) {
        this.addTarifaCombustibleSuplementoContrateCar({
          indSuplementoCar: this.indSuplementoCar,
          category_id: this.category.category_id.id,
          marca_id: null,
          price: 0,
        })
      } else {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible.length;
          // eslint-disable-next-line no-plusplus
          index++
        ) {
          const element = this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible[index]

          if (element.category_id === this.category.category_id.id) {
            this.deleteTarifaCombustibleSuplementoContrateCar({ pos: index, indSuplementoCar: this.indSuplementoCar })
            break
          }
        }
      }
    },
  },
}
</script>
