var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('td',{staticClass:"text-rigth",style:(_vm.pos < _vm.suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : ''),attrs:{"colspan":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-select',{staticClass:"mt-3",attrs:{"label":_vm.$t('lbl.brandModel'),"items":_vm.marcasModelo,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-detailst":"","multiple":"","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.segurosEdadConductor.marca_id),callback:function ($$v) {_vm.$set(_vm.segurosEdadConductor, "marca_id", $$v)},expression:"segurosEdadConductor.marca_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.suplementoCar.seguros_edad_conductor.length > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteSeguroEdadConductorSuplementoContrateCar({
                  pos: _vm.pos,
                  indSuplementoCar: _vm.indSuplementoCar,
                })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,false,2526094785)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.pos === 0 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mt-3",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addSeguroEdadConductorSuplementoContrateCar({
                  indSuplementoCar: _vm.indSuplementoCar,
                  category_id: [-1],
                  marca_id: null,
                  edad_min: null,
                  edad_max: null,
                })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,2204631084)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1)],1)],1),_c('td',{style:(_vm.pos < _vm.suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : '')},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","type":"number","prefix":"$","disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.segurosEdadConductor.price),callback:function ($$v) {_vm.$set(_vm.segurosEdadConductor, "price", $$v)},expression:"segurosEdadConductor.price"}})],1),_c('td',{style:(_vm.pos < _vm.suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : '')},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.menorDe'),"outlined":"","dense":"","hide-details":"","type":"number","suffix":_vm.$t('lbl.years'),"disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.segurosEdadConductor.edad_min),callback:function ($$v) {_vm.$set(_vm.segurosEdadConductor, "edad_min", $$v)},expression:"segurosEdadConductor.edad_min"}})],1),_c('td',{style:(_vm.pos < _vm.suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : '')},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.mayorDe'),"outlined":"","dense":"","hide-details":"","type":"number","suffix":_vm.$t('lbl.years'),"disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.segurosEdadConductor.edad_max),callback:function ($$v) {_vm.$set(_vm.segurosEdadConductor, "edad_max", $$v)},expression:"segurosEdadConductor.edad_max"}})],1),_c('td',{style:(_vm.pos < _vm.suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : '')}),_c('td',{style:(_vm.pos < _vm.suplementoCar.seguros_edad_conductor.length - 1 ? 'border: 0px solid black' : '')})])}
var staticRenderFns = []

export { render, staticRenderFns }