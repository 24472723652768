<template>
  <fragment>
    <tr
      v-for="(segurosEdadConductor, indSeguroEdad) in suplementoCar.seguros_edad_conductor"
      :key="indSeguroEdad"
    >
      <td style="border: 0px solid black"></td>
      <SuplementoEdadConductorCategoryOne
        :seguros-edad-conductor="segurosEdadConductor"
        :pos="indSeguroEdad"
        :suplemento-car="suplementoCar"
        :ind-suplemento-car="indSuplementoCar"
      />
      <!--<td :style="indCategory < categoryContrateCar.length - 1 ? 'border: 0px solid black;' : ''">
        <fragment v-if="model.check">
          <fragment
            v-for="(tarifaCombustible, indTarifaCombustible) in suplementoCar.tarifas_combustible"
            :key="indTarifaCombustible"
          >
            <fragment v-if="tarifaCombustible.category_id === category.category_id.id">
              <v-text-field
                v-model="tarifaCombustible.price"
                :label="$t('lbl.price')"
                outlined
                dense
                hide-details
                prefix="$"
                type="number"
                width="auto"
              ></v-text-field>
            </fragment>
          </fragment>
        </fragment>
      </td>-->
    </tr>
  </fragment>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiDeleteOutline, mdiMenuDown, mdiMenuRight, mdiPlus,
} from '@mdi/js'
import SuplementoEdadConductorCategoryOne from './SuplementoEdadConductorCategoryOne.vue'

export default {
  components: {
    SuplementoEdadConductorCategoryOne,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplementoCar: {
      type: Object,
    },
    indSuplementoCar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
        mdiPlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
      categories: [],
      searchCategory: null,
    }
  },
  computed: {
    ...mapState({
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
    }),
  },

  methods: {
    ...mapMutations(['addTarifaCombustibleSuplementoContrateCar', 'deleteTarifaCombustibleSuplementoContrateCar']),

    /* changeCheck() {
      if (this.model.check) {
        this.addTarifaCombustibleSuplementoContrateCar({
          indSuplementoCar: this.indSuplementoCar,
          category_id: this.category.category_id.id,
          marca_id: null,
          price: 0,
        })
      } else {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible.length;
          // eslint-disable-next-line no-plusplus
          index++
        ) {
          const element = this.suplementosContrateCar[this.indSuplementoCar].tarifas_combustible[index]

          if (element.category_id === this.category.category_id.id) {
            this.deleteTarifaCombustibleSuplementoContrateCar({ pos: index, indSuplementoCar: this.indSuplementoCar })
            break
          }
        }
      }
    }, */
  },
}
</script>
